<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Forgot Password? 🔒
          </p>
          <p class="mb-2">
            Enter your email and we'll send you instructions to reset your password
          </p>
        </v-card-text>

        <!-- login form -->
        <v-form
          ref="registerForm"
        >
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-text-field
                  v-model="email"
                  label="Enter your email address"
                  required
                ></v-text-field>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-text-field
                  v-model="token"
                  label="Enter token from email"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newPassword"
                  outlined
                  :type="isPasswordVisible ? 'text' : 'password'"
                  label="Enter New Password"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                  hide-details
                  @click:append="isPasswordVisible = !isPasswordVisible"
                ></v-text-field>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <div class="pa-4 text-center">
                <v-img
                  class="mb-4"
                  contain
                  height="128"
                  src="https://www.svgrepo.com/show/43086/success.svg"
                ></v-img>
                <h3 class="title font-weight-light mb-2">
                  Waiting...
                </h3>
                <router-link
                  :to="{name:'auth-login'}"
                  class="d-flex align-center text-sm"
                >
                  <v-icon
                    size="24"
                    color="primary"
                  >
                    {{ icons.mdiChevronLeft }}
                  </v-icon>
                  <span>Back to login</span>
                </router-link>
              </div>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              :disabled="step === 1"
              text
              @click="step--"
            >
              Back
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="step === 3"
              color="primary"
              depressed
              @click="submitRequest()"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import store from '@/store'
import { mdiChevronLeft } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'
import { useRouter } from '@core/utils'

export default {
  setup() {
    const AUTH_APP = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP)) {
      store.registerModule(AUTH_APP, authenticationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP)) store.unregisterModule(AUTH_APP)
    })

    const { requestPasswordResetEmail, resetPassword, errorMessages, userResponse } = useAuthenticationService()

    const isPasswordVisible = ref(false)
    const email = ref('')
    const { router } = useRouter()

    const redirect = () => {
      try {
        // On success redirect to home
        router.push('/reset-password')
      } catch (err) {
        errorMessages.validation.push('Invalid Entry')

        // console.log(err)
      }
    }

    return {
      isPasswordVisible,
      email,
      resetPassword,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      requestPasswordResetEmail,
      errorMessages,

      userResponse,
      redirect,

      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    let step = 1
    if (this.$route.params.token && this.$route.params.email) {
      step = 2
    }

    return {
      step,
      token: this.$route.params.token,
      email: this.$route.params.email,
      newPassword: '',
    }
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Enter email address'
        case 2:
          return 'Check email and enter token and new password'
        default:
          return 'Updated! Now please login'
      }
    },
  },
  methods: {
    async submitRequest() {
      this.step++
      if (this.step === 2) {
        await this.requestPasswordResetEmail(this.email)
      }

      if (this.step === 3) {
        const DTO = {
          Email: this.email,
          OldPassword: this.token,
          NewPassword: this.newPassword,
        }
        await this.resetPassword(DTO)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
